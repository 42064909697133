import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { Table } from "../../../s25-table/Table";
import { GenericTableButtonComponent } from "../../../s25-table/generics/generic.table.button.component";
import { Bind } from "../../../../decorators/bind.decorator";
import { EventCreationFormService, EventFormConfigData } from "../../../../services/event.creation.form.service";
import { ModalService } from "../../../modal/modal.service";
import { S25TableComponent } from "../../../s25-table/s25.table.component";
import { CacheRepository } from "../../../../decorators/cache.decorator";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { GenericTableFadePreviewComponent } from "../../../s25-table/generics/generic.table.fade.preview.component";
import { GenericTableRenderHTMLComponent } from "../../../s25-table/generics/generic.table.render.html.component";
import { MultiselectModelI } from "../../../s25-multiselect/s25.multiselect.component";
import { MasterDefinitionTagsService } from "../../../../services/master.definitions/master.definition.tags.service";
import { MasterDefTag } from "../../../../pojo/MasterDefTag";
import { S25MdTagMultiSelectComponent } from "../../../master-definitions/s25.md.tag.picker.component";

@TypeManagerDecorator("s25-ng-event-form-configs")
@Component({
    selector: "s25-ng-event-form-configs",
    template: `
        <h2 class="c-margin-bottom--single">Config Settings</h2>
        <button class="aw-button aw-button--primary" (click)="onAddClick()">Add Config</button>
        <s25-ng-table
            [dataSource]="tableConfig"
            [columnSortable]="true"
            [hasFilter]="true"
            [hasRefresh]="true"
        ></s25-ng-table>
    `,
    styles: `
        button {
            margin-bottom: 1em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EventFormConfigListComponent implements OnInit {
    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    tableConfig: Table.DataSource;

    ngOnInit() {
        const columns: Table.Column[] = [
            { id: "name", header: "Config", align: "left" },
            { id: "isDefault", header: "Default", width: 70 },
            { id: "eventType", header: "Default Event Type", minWidth: 150 },
            { id: "tags", header: "Tags", minWidth: 150, content: { component: S25MdTagMultiSelectComponent } },
            {
                id: "message",
                header: "Message",
                sortable: false,
                content: {
                    component: GenericTableFadePreviewComponent,
                },
            },
            GenericTableButtonComponent.Column("Copy", this.onCopyClick, "outline"),
            GenericTableButtonComponent.Column("Edit", this.onEditClick, "outline"),
            GenericTableButtonComponent.Column("Delete", this.onDeleteClick, "danger--outline"),
        ];

        this.tableConfig = {
            type: "unpaginated",
            columns: columns,
            dataSource: this.getAllConfigs,
        };
    }

    @Bind
    async getAllConfigs(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByService("EventCreationFormService"); // When user refreshes invalidate cache
        const data = await EventCreationFormService.getAllConfigs();
        const configs = data.map(this.mapConfigToRow);
        return {
            rows: configs,
            totalRows: configs.length,
        };
    }

    @Bind
    mapConfigToRow(config: EventFormConfigData): Table.Row {
        return {
            id: config.itemId,
            name: config.itemName,
            cells: {
                isDefault: { text: config.is_default ? "Yes" : "No" },
                name: { text: config.itemName },
                eventType: { text: config.default_event_type_name ?? "" },
                tags: {
                    inputs: {
                        selectedItems: S25Util.array
                            .forceArray(config.tags)
                            .map((tag) => ({ itemId: tag.tagId, itemName: tag.tagName })),
                        selectBean: { hasCommit: true },
                    },
                    outputs: {
                        selectedItemsChange: (selectBean) => this.onTagsUpdate(selectBean, config.itemId),
                    },
                },
                message: {
                    inputs: {
                        cell: {
                            component: GenericTableRenderHTMLComponent,
                            inputs: { text: config.help_message ?? "", isHtml: true },
                        },
                    },
                },
            },
        };
    }

    onAddClick() {
        ModalService.modal("edit-event-form-config", {
            title: "Create New Event Form Config",
            mode: "create",
            onSave: this.tableComponent.refresh,
        });
    }

    @Bind
    onEditClick(row: Table.Row) {
        ModalService.modal("edit-event-form-config", {
            id: row.id,
            title: `Edit Config: ${row.name}`,
            onSave: this.tableComponent.refresh,
        });
    }

    @Bind
    onCopyClick(row: Table.Row) {
        ModalService.modal("edit-event-form-config", {
            id: row.id,
            title: `Copy Config: ${row.name} copy`,
            mode: "copy",
            onSave: this.tableComponent.refresh,
        });
    }

    @Bind
    async onDeleteClick(row: Table.Row) {
        let dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: "Are you sure you want to delete this configuration?",
                title: "Confirm Deletion",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no
        const ok = !!EventCreationFormService.delConfig(row.id).catch(S25Util.showError);
        if (!ok) return;
        this.tableComponent.refresh();
    }

    @Bind
    onTagsUpdate(tagsModel: MultiselectModelI, configurationId: number) {
        const added = tagsModel.addedItems?.map((items) => +items.itemId);
        const removed = tagsModel.removedItems?.map((items) => +items.itemId);
        if (!added?.length && !removed?.length) return;

        return MasterDefinitionTagsService.editTagsOnObject(
            MasterDefTag.types.event_forms,
            0,
            configurationId,
            added,
            removed,
        );
    }
}
